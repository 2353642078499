<template>
  <div class="">
    Customer Reports
  </div>
</template>
<script type="text/javascript">
export default {
  name: 'CustomerReports'
}
</script>
